.App {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.app-header {
  font-size: 2.2vh;
  height: 5vh;
  display: flex;
  padding-left: 3vw;
  align-items: center;
  font-weight: 600;
  &.small-screen {
    font-size: 3.5vw;
  }
}

.header-description {
  margin-left: auto;
  margin-right: 3vw;
  &.small-screen {
    font-size: 2.1vw;
  }
}

.app-footer {
  font-size: 2.2vh;
  color: white;
  background-color: #1D3D78;
  height: 5vh;
  display: flex;
  padding-left: 3vw;
  align-items: center;
  margin-top: auto;
  &.small-screen {
    font-size: 1.9vw;
  }
}

.question-banner-image {
  height: 25%;
  align-self: center;
  &.demographics {
    margin-right: 60px;
    height: 90%;
  }
}

.emergency-button {
  margin-left: 57px;
  height: 30px;
  width: 450px;
  padding: 10px;
  border-radius: 15px;
  color: white;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-decoration: none;
  font-weight: 600;
  border: solid 3px yellow;
  margin-bottom: -55px;
}

.emergency-button-container {
  height: 60px;
}

.pop-up-comments {
  flex-grow: 1;
}

.pop-up-bottom-row {
  display: flex;
  width: 100%;
  place-content: flex-end;
}

.pop-up-comments-box {
  height: 10vh;
  width: calc(100% - 20px);
  padding: 10px;
  font-family: system-ui;
  resize: none;
}

.pop-up-image {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.welcome-pop-up-text {
  margin-top: 15px;
}

.pop-up-content {
  padding: 18px;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  margin-top: 3vh;
  &.small-screen {
    margin-top: 0px;
  }
}

.star-row {
  display: flex;
  margin-top: 15px;
  align-self: center;
  gap: 15px;
}

.banner-image {
  width: 100%;
  max-height: 100%;
  &.welcome {
    width: 50%;
  }
  &.resource {
    margin-top: -100px;
  }
}

.question-banner-description {
  margin-left: 3vw;
  font-size: 1.4vw;
  margin-top: 1vh;
  margin-bottom: 1.5vh;
  width: 95vw;
  &.diversity {
    margin-bottom: -10px;
  }
  &.small-screen {
    font-size: 3.25vw;
  }
}

.progress-bar-container{
  display: flex;
  align-self: center;
  margin-top: 25px
}

.progress-bar {
  width: 250px;
  border: solid 1px black;
  margin-left: 5px;
}

.landing-page-content {
  height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-right: 15px;
}

.header-text {
  margin-left: 3vw;
  font-size: 2.7vw;
  text-align: left;
  color: #1D3D78;
  font-weight: 700;
  &.home {
    margin-top: 3vh;
  }
  &.small {
    font-size: 3vh
  }
  &.long {
    width: 100%;
    height: fit-content;
  }
  &.small-screen {
    font-size: 5.5vw;
    &.small {
      font-size: 4.8vw;
    }
  }
}
.subheader-text {
  margin-left: 3vw;
  font-size: 3vh;
  text-align: left;
  margin-bottom: 2.5vh;
  color: black;
  &.small-screen {
    font-size: 4vw;
  }
}

.page-content {
  width: 100vw;
  flex-grow: 1;
  display: flex;
}

.picture-content {
  max-width: 20vw;
  height: 65vh;
  display: flex;
  justify-content: center;
  margin-right: 3vw;
  margin-left: auto;
}

.question-item-container {
  margin-top: 1vh;
}

.footer-image {
  margin-top: auto;
  margin-left: 3vw;
  margin-right: 3vw;
  height: 22vh;
}

.landing-page-list { 
  margin-left: 3vw;
  margin-right: 3vw;
  font-size: 3vh;
  display: flex;
  width: 94vw;
  flex-wrap: wrap;
  padding-top: 1vh;
  color: #1D3D78;
  margin-bottom: -5vh;
  justify-content: space-between;
  height: 47vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: .1vh;
  padding-right: .1vh;
  &.small-screen {
    height: 64vh;
  }
}

.landing-page-list-item {
width: 33%;
display: flex;
box-shadow: 0.2vh 0.2vh 1vh darkgray;
margin-bottom: 1vh;
height: 14vh;
  &.small-screen {
    width: 45%;
    height: 11vh;
  }
  &.multi-cat {
    width: 25%;
  }
  &:hover {
    cursor: pointer;
    background-color: aliceblue;
  }
}

.icon-label {
  font-size: 3.5vh;
    height: fit-content;
    flex-grow: 1;
    align-self: center;
    display: flex;
    place-content: center;
    text-align: center;
}

.landing-page-list-icon {
  background-color: #1D3D78;
  color: white;
  margin-right: .5vw;
  width: 1.8em;
  height: 1.6em;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding-top: .3vh;
  width: 4.2vh;
  min-width: 4.2vh;
  height: 4vh;
  margin-top: 2vh;
  margin-left: 1vw;
}

.briefcase-icon {
  display: flex;
  &.landing {
    margin-top: .25vh;
  }
}

.second-page-list-icon {
  background-color: #1D3D78;
  color: white;
  margin-right: .7vw;
  width: 1.5vw;
  height: 1.4vw;
  text-align: center;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  padding-top: .1vw;
  &.small-screen {
    width: 3.5vw;
    height: 3.4vw;
  }
}


.breadcrumb-chevron {
  height: auto;
  display: flex;
}

.breadcrumb-row {
  display: flex;
  margin-left: 3vw;
  margin-top: 1vh;
  margin-bottom: .2vh;
  align-items: flex-end;
}

.standard-breadcrumb {
  color: #1D3D78;
  text-decoration: underline;
  font-size: 2vh;
  line-height: 2.1vh;
  &.small-screen {
    font-size: 1.7vh;
  }
  &:hover{
    cursor: pointer;
  }
}

.welcome-pop-up {
  position: absolute;
  left: calc(50% - 400px);
  top: calc(50% - 200px);
  height: 400px;
  width: 800px;
  background-color: white;
  z-index: 3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  &.small-screen {
    left: 3vw;
    width: 94vw;
    height: 40vh;
  }
  &.feedback {
    height: fit-content;
  }
}

.pop-up-header {
  background-color: #1D3D78;
  color: white;
  font-size: 24px;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.final-breadcrumb {
  text-align: left;
  font-size: 2vh;
  color: #1D3D78;
  font-weight: 700;
  line-height: 2.1vh;
  &.small-screen {
    font-size: 1.7vh;
    line-height: 1.7vh;
  }
}

.resource-header {
  font-size: 2vh;
  font-weight: 600;
  background-color: lightgray;
  color: #1D3D78;
  font-weight: 700;
  height: 4.9vh;
  display: flex;
  align-items: center;
  padding-left: 15px;
  border-bottom: 2px solid white;
  &:hover {
    cursor: pointer;
  }
}

.second-page-list-item-divider {
  border-bottom: .1vh solid black;
  width: 100%;
  margin-top: .4vh;
}

.second-page-list {
  margin-left: 3vw;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: left;
  overflow-y: auto;
  flex-wrap: nowrap;
  overflow-x: hidden;
  padding-right: 10px;
  height: 100%;
  width: 77vw;
  gap: 2vh;
  &.small-screen {
    width: 95vw;
  }
}

.diversity-page-list {
  margin-left: 10px;
  font-size: 17px;
  display: flex;
  align-items: left;
  flex-grow: 1;
  padding-bottom: 20px;
  flex-wrap: wrap;
  width: 69%;
  align-content: flex-start;
  &.small-screen {
    width: 100%;
    margin-left: 0;
  }
}

.screen-dim {
  position: absolute;
  z-index: 2;
  background-color: black;
  width: 100vw;
  height: 100vh;
  opacity: 50%;
}

.resource-image {
  margin-right: 15px;
  width: 16%;
  align-self: flex-end;
  margin-left: 10px;
}

.graphic-subtext {
  font-size: .8em;
}

.pop-up-graphic {
  height: 100%;
  flex-basis: 100%;
  flex-grow: 1;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.diversity-page-container {
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100vw - 5px);
    margin-left: 3vw;
}

.button-container {
  width: 93%;
  display: flex;
  justify-content: flex-end;
}

.pop-up-graphic-row {
  display: flex;
  height: 40%;
  align-items: center;
}

.pop-up-graphic-box {
  width: 21%;
  border: 1px solid black;
  min-height: 100%;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &.gray {
    background-color: lightgray;
  }
}

.graphic-call-out {
  display: flex;
  margin-top: 10px;
}

.resource-left-panel {
  display: flex;
  flex-direction: column;
  width: 97.5vw;
}

.demo-tool-tip {
  font-size: 2vh;
}

.information-icon {
  position: absolute;
  top: 10vh;
  right: 3vw;
  font-size: 3vw;
  color: #4370d1;
  &.small-screen {
    bottom: 7vh;
    right: 5vw;
    top: auto;
  }
  &:hover {
    cursor: pointer;
  }
}

.resource-buttons {
  margin-left: 3vw;
  height: 65px;
  display: flex;
  &.small-screen {
    max-height: 6vh;
    min-height: 6vh;
  }
}

.resource-box-container {
  display: flex;
  flex-direction: column;
  transition: all .75s;
  &.hidden {
    max-height: 50px;
  }
}

.resource-boxes {
  margin-left: 3vw;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-top: 1vw;
}
.resource-content {
  display: flex;
  height: 70vh;
  &.small-screen {
    height: 66vh;
  }
}
.resource-link {
  white-space: nowrap;
}
.resource-list {
  gap: 1vh;
  max-width: 100%;
  padding-bottom: 1vh;
  overflow: hidden;
  &.hidden {
    max-height: 0px;
  }
}

.expand-icon {
  margin-left: auto;
  margin-right: 1vw;
  margin-bottom: -1vh;
}

.resource-page-content {
  display: flex;
  flex-direction: column;
  height: 87vh;
  overflow: hidden;
}

.second-page-content {
      display: flex;
    height: 87vh;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: auto;
    flex-direction: column;
    overflow-x: hidden;
    flex-wrap: nowrap;
    &.small-screen {
      margin-top: 2vh;
    }
}

.diversity-page-content {
  height: 87vh;
  display: flex;
  flex-direction: column;
}

.second-page-list-item {
line-height: 3.1vh;
color: black;
display: flex;
font-size: 3vh;
align-items: center;
color: #1D3D78;
border: .3vh solid #1D3D78;
border-radius: .8vh;
padding-left: .6vw;
padding-right: 6px;
height: 11vh;
  &.emergency {
    border: .3vh solid red;
  }
  &:hover {
    cursor: pointer;
    color: #1D3D78;
    background-color: aliceblue;
  }
}

.second-page-list-item-subheader {
  font-size: 1.2vw;
  &.small-screen {
    text-wrap: wrap;
    font-size: 3.3vw;
    line-height: 3vw;
    margin-left: .5vw;
  }
}

.second-page-list-item-content {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.second-page-list-item-row {
  display: flex;
  align-items: center;
  font-size: 2.7vh;
  margin-bottom: .7vh;
  &.bottom {
    margin-top: .7vh;
    margin-bottom: 0vh;
  }
  &.small-screen {
    font-size: 4vw;
    line-height: 4.4vw;
    margin-bottom: 0;
    &.bottom {
      font-size: 3.9vw;
    }
  }
}

.resource-row {
  font-size: 1vw;
  align-items: flex-start;
  margin-top: 1.5vh;
  &.small-screen {
    font-size: 3.5vw;
  }
}

.next-button {
  margin-top: 1.3vh;
  margin-bottom: 1.3vh;
  background-color: #4370d1;
  width: 8vw;;
  color: white;
  height: 2.4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vh;
  text-decoration: none;
  font-size: 2vh;
  &.right {
    margin-left: auto;
  }
  &.left {
    margin-left: 2vw;
  }
  &.small {
    margin-right: 20px;
    margin-top: -5px;
  }
  &.blue {
    background-color: #4370d1;
  }
  &.gray {
    background-color: gray;
  }
  &.small-screen {
    width: 25vw;
    height: 4vh;
    font-size: 1.7vh;
  }
  &:hover {
    cursor: pointer;
    &.gray {
      cursor: default;
    }
  }
}

.diversity-page-list-icon {
  margin-bottom: -8px;
  margin-right: 10px;
}

.diversity-header {
  margin-left: 10px;
  font-weight: 600;
  display: flex;
}

.diversity-options-container {
  display: flex;
  gap: 10px;
  font-size: 16px;
  margin-left: 8px;
  margin-top: 5px;
  flex-wrap: wrap;
}

.diversity-option-pill {
  border: 1px solid black;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 10px;
  text-wrap: nowrap;
  &.selected {
    background-color: #1D3D78;
    color: white;
  }
  &:hover {
    cursor: pointer;
    background-color: aliceblue;
    &.selected {
      background-color: #1D3D78;
      color: lightblue;
    }
  }
}

.diversity-page-list-item {
  line-height: 30px;
  color: black;
  width: fit-content;
  margin-top: 15px;
  font-size: 16px;
  align-items: center;
  flex-grow: 1;
  color: #1D3D78;
  border-radius: 15px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  }

.back-icon {
  display: flex;
  gap: 5px;
  font-size: 24px;
  margin-left: 30px;
  color: black;
  line-height: 32px;
    &:hover {
      color: #1D3D78;
      cursor: pointer;
    }
}
.back-arrow {
  font-size: 32px;
}